import { ReactNode } from 'react'
import styled from 'styled-components'
import { BorderRadiuses, Transitions } from 'styles'
import { Tooltip } from 'components/atoms/Tooltip'
import { QuestionIcon } from 'components/atoms/Icons'
import { TextInline } from '../Text'
import { Asterisk } from '../Asterisk'

interface InputLabelProps {
  children: ReactNode
  className?: string
  error?: string
  disabled?: boolean
  htmlFor?: string
  isRequired?: boolean
  isOptional?: boolean
  tooltip?: string | ReactNode
}

export const InputLabel = ({
  children,
  className,
  error,
  disabled,
  htmlFor,
  isRequired,
  isOptional,
  tooltip,
}: InputLabelProps) => {
  return (
    <Label className={className} error={error} disabled={disabled} htmlFor={htmlFor}>
      {children}
      {isRequired && <Asterisk />}
      {isOptional && (
        <TextInline color="light" variant="body2" bold>
          (Optional)
        </TextInline>
      )}
      {tooltip && (
        <Tooltip tooltip={tooltip}>
          <QuestionIcon />
        </Tooltip>
      )}
    </Label>
  )
}

export const Label = styled.label<Pick<InputLabelProps, 'error' | 'disabled'>>`
  position: absolute;
  top: -50%;
  left: 12px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  transform: translateY(50%);
  background-color: ${({ theme }) => theme.colors.White};
  border-radius: ${BorderRadiuses.l};
  padding: 0 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.Text01 : 'currentColor')};
  cursor: ${({ disabled, error, htmlFor }) => {
    if (disabled) return 'not-allowed'
    if (error) return 'help'
    if (htmlFor) return 'pointer'
    return 'auto'
  }};
  transition: ${Transitions.all};
`
