import styled from 'styled-components'

export interface AsteriskProps {
  className?: string
}

export const Asterisk = ({ className }: AsteriskProps) => <AsteriskWrapper className={className}>*</AsteriskWrapper>

export const AsteriskWrapper = styled.span`
  color: ${({ theme }) => theme.colors.PersianRed};
`
