import { ErrorIcon } from 'components/atoms/Icons'
import { BasicInputWrapper } from 'components/atoms/Input'
import { Message } from 'components/atoms/Message'
import { ReactNode } from 'react'

export interface Props {
  error?: string
  children: ReactNode
}

export const InputErrorWrapper = ({ error, children }: Props) => {
  return (
    <BasicInputWrapper>
      {children}
      {error && (
        <Message type="error" icon={<ErrorIcon />} wide>
          {error}
        </Message>
      )}
    </BasicInputWrapper>
  )
}
