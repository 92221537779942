import { Input, InputProps } from 'components/atoms/Input'
import { useFormContext } from 'react-hook-form'
import { InputErrorWrapper } from 'components/molecules/InputErrorWrapper'
import { useFormError } from 'hooks'
import { ReactNode } from 'react'
import { FormDescriptionText } from 'components/atoms/Form'

type Props = Omit<InputProps, 'value' | 'error'>

export interface FormInputProps extends Props {
  description?: ReactNode
  isNumber?: boolean
}

export const FormInput = ({ id, disabled, description, isNumber, placeholder, ...props }: FormInputProps) => {
  const {
    register,
    formState: { isSubmitting },
  } = useFormContext()

  const error = useFormError(id)

  return (
    <InputErrorWrapper error={error}>
      <Input
        id={id}
        inputMode={isNumber ? 'numeric' : 'text'}
        error={error}
        disabled={disabled || isSubmitting}
        placeholder={isNumber ? '0' : placeholder}
        {...props}
        {...register(id, { valueAsNumber: isNumber })}
      />
      {description && (
        <FormDescriptionText variant="body2" color="light">
          {description}
        </FormDescriptionText>
      )}
    </InputErrorWrapper>
  )
}
