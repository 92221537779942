import { Statistic, StatisticProps } from '../Statistic'
import { Currency, CurrencyValue } from '@usedapp/core'
import { TextColor, TextInline, TextVariant } from 'components/atoms/Text'
import { ValueChange } from 'components/Portfolios/AssetComposition/ValueChange'
import { Row } from 'components/atoms/Row'
import styled from 'styled-components'
import { IconContainer } from 'components/atoms/Icons'
import { formatCurrencyValue } from 'utils/formatting'
import { BigNumber } from 'ethers'
import { PercentageValue } from 'components/molecules/PercentageValue'
import { Column } from 'components/atoms/Column'

export interface CurrencyStatisticProps extends Omit<StatisticProps, 'text'> {
  value: CurrencyValue | undefined | null
  newValue?: CurrencyValue | undefined
  placeholder?: string
  fixedPrecisionDigits?: number
  currency?: Currency
  textVariant?: TextVariant
  currencyColor?: TextColor
  shortenBigValues?: boolean
  maximumFractionDigits?: number
  showNoChange?: boolean
  currencyBold?: boolean
  currencyExtraBold?: boolean
  suffix?: string
  valuesView?: 'row' | 'column'
  percentageValueTotalAmount?: BigNumber
  newPercentageValueTotalAmount?: BigNumber
  newValueSuffix?: string
  newValueColorOverwrite?: string
  changeView?: 'column' | 'row'
  newValueTickerVariant?: TextVariant
}

export function CurrencyStatistic({
  value,
  newValue,
  placeholder = '-',
  fixedPrecisionDigits = 2,
  currency,
  textVariant,
  currencyColor,
  shortenBigValues,
  maximumFractionDigits,
  showNoChange,
  currencyBold,
  currencyExtraBold,
  suffix,
  valuesView,
  percentageValueTotalAmount,
  newPercentageValueTotalAmount,
  newValueSuffix,
  newValueColorOverwrite,
  changeView,
  newValueTickerVariant,
  ...args
}: CurrencyStatisticProps) {
  const targetCurrency = currency || value?.currency

  return (
    <Statistic
      {...args}
      text={
        <StatisticText
          newValue={newValue}
          value={value}
          placeholder={placeholder}
          fixedPrecisionDigits={fixedPrecisionDigits}
          currency={targetCurrency}
          textVariant={textVariant}
          currencyColor={currencyColor}
          shortenBigValues={shortenBigValues}
          maximumFractionDigits={maximumFractionDigits}
          showNoChange={showNoChange}
          currencyBold={currencyBold}
          currencyExtraBold={currencyExtraBold}
          suffix={suffix}
          valuesView={valuesView}
          percentageValueTotalAmount={percentageValueTotalAmount}
          newPercentageValueTotalAmount={newPercentageValueTotalAmount}
          newValueSuffix={newValueSuffix}
          changeView={changeView}
          newValueColorOverwrite={newValueColorOverwrite}
          newValueTickerVariant={newValueTickerVariant}
        />
      }
    />
  )
}

interface StatisticTextProps {
  value: CurrencyValue | undefined | null
  newValue?: CurrencyValue | undefined
  placeholder: string
  fixedPrecisionDigits: number
  currency?: Currency
  textVariant?: TextVariant
  currencyColor?: TextColor
  shortenBigValues?: boolean
  maximumFractionDigits?: number
  showNoChange?: boolean
  currencyBold?: boolean
  currencyExtraBold?: boolean
  suffix?: string
  valuesView?: 'row' | 'column'
  percentageValueAmount?: BigNumber
  percentageValueTotalAmount?: BigNumber
  newPercentageValueAmount?: BigNumber
  newPercentageValueTotalAmount?: BigNumber
  newValueSuffix?: string
  changeView?: 'column' | 'row'
  newValueColorOverwrite?: string
  newValueTickerVariant?: TextVariant
}

const StatisticText = ({
  value,
  newValue,
  placeholder,
  fixedPrecisionDigits,
  currency,
  textVariant = 'body2',
  currencyColor = 'light',
  shortenBigValues,
  maximumFractionDigits,
  showNoChange,
  currencyBold,
  currencyExtraBold,
  valuesView = 'row',
  percentageValueTotalAmount,
  newPercentageValueTotalAmount,
  suffix,
  newValueSuffix,
  changeView = 'row',
  newValueColorOverwrite,
  newValueTickerVariant,
}: StatisticTextProps) => {
  if (!value) {
    return <>{placeholder}</>
  }

  const { value: oldValue } = value
  const Wrapper = changeView === 'row' ? Row : Column

  return (
    <Wrapper>
      <Row>
        {formatCurrencyValue(value, { fixedPrecisionDigits, shortenBigValues, maximumFractionDigits })}
        &nbsp;
        <Values valuesView={valuesView}>
          {currency && (
            <TextInline color={currencyColor} variant={textVariant} bold={currencyBold} extraBold={currencyExtraBold}>
              {currency.ticker.toUpperCase()}
              <Suffix>{suffix}</Suffix>
            </TextInline>
          )}
          {percentageValueTotalAmount && (
            <PercentageValue amount={value.value} totalAmount={percentageValueTotalAmount} />
          )}
        </Values>
      </Row>
      {newValue && (
        <ValueChange
          oldValue={oldValue}
          newValue={newValue}
          showNoChange={showNoChange}
          fixedPrecisionDigits={fixedPrecisionDigits}
          tickerVariant={newValueTickerVariant}
          suffix={newValueSuffix}
          view={changeView}
          colorOverwrite={newValueColorOverwrite}
        />
      )}
      {newValue && newPercentageValueTotalAmount && (
        <PercentageValue amount={newValue.value} totalAmount={newPercentageValueTotalAmount} asNewValue />
      )}
    </Wrapper>
  )
}

const Values = styled.div<{ valuesView?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ valuesView = 'column' }) => valuesView};

  ${IconContainer} {
    margin-left: 4px;
  }
`

const Suffix = styled.span`
  margin-left: 2px;
`
