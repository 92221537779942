import { useCalls } from '@usedapp/core'
import { Contract, utils } from 'ethers'

import { toBlockchainCallResult } from './BlockchainCallResult'
import { BlockchainCallResult, ContractFunctionArgs } from './types'
import { SupportedChainId } from 'constants/chain'

export function useChainCalls<T>(
  chainId: SupportedChainId | undefined,
  addresses: (string | undefined)[],
  functionName: string,
  abi?: utils.Interface,
  args?: ContractFunctionArgs[],
  skip?: boolean,
): BlockchainCallResult<T[]>[] {
  skip = skip || chainId === undefined

  const calls = addresses.map(
    (address, index) =>
      !skip &&
      address &&
      abi && {
        contract: new Contract(address, abi),
        method: functionName,
        args: args?.[index] ?? [],
      },
  )
  const results = useCalls(calls, { chainId })
  return toBlockchainCallResult<T>(skip, functionName, addresses, results)
}

export function useChainCall<T>(
  chainId: SupportedChainId | undefined,
  poolAddress: string | undefined,
  functionName: string,
  abi?: utils.Interface,
  args?: ContractFunctionArgs[],
  skip?: boolean,
) {
  return useChainCalls<T>(chainId, [poolAddress], functionName, abi, args ? [...args] : undefined, skip)[0]
}
