import styled, { css, useTheme } from 'styled-components'
import { BigNumber } from 'ethers'
import { Text, TextVariant } from 'components/atoms/Text'
import { ArrowForwardIcon } from 'components/atoms/Icons'
import { CurrencyValue } from '@usedapp/core'
import { CustomTheme } from 'styles'
import { Row } from 'components/atoms/Row'

interface ValueChangeProps {
  oldValue: BigNumber
  newValue: CurrencyValue
  withTicker?: boolean
  fixedPrecisionDigits?: number
  showNoChange?: boolean
  tickerVariant?: TextVariant
  suffix?: string
  view?: 'row' | 'column'
  colorOverwrite?: string
}

export function ValueChange({
  oldValue,
  newValue,
  withTicker = true,
  fixedPrecisionDigits = 2,
  showNoChange = false,
  tickerVariant,
  suffix,
  view = 'row',
  colorOverwrite,
}: ValueChangeProps) {
  const { colors } = useTheme()
  if (!showNoChange && (newValue.value.isNegative() || oldValue?.eq(newValue.value))) {
    return null
  }
  const color = colorOverwrite ?? getColor(newValue, oldValue, colors)

  return (
    <ValueChangeContainer valueColor={color} view={view}>
      <StyledArrowForwardIcon color={colors.HeavyMetal} view={view} />
      <StyledRow>
        {newValue.format({
          fixedPrecisionDigits: newValue.isZero() ? 0 : fixedPrecisionDigits,
          useFixedPrecision: true,
          suffix: '',
        })}
        {withTicker && (
          <Text color="light" variant={tickerVariant}>
            {newValue.currency.ticker}
            {suffix && <Suffix>{suffix}</Suffix>}
          </Text>
        )}
      </StyledRow>
    </ValueChangeContainer>
  )
}

const ValueChangeContainer = styled.div<{ valueColor: string } & Pick<ValueChangeProps, 'view'>>`
  display: flex;
  align-items: ${({ view = 'row' }) => (view === 'row' ? 'center' : 'start')};
  gap: ${({ view = 'row' }) => (view === 'row' ? 4 : 8)}px;
  color: ${({ valueColor }) => valueColor};
  flex-direction: ${({ view = 'row' }) => view};

  ${({ view = 'row' }) =>
    view === 'column' &&
    css`
      margin-top: 8px;
    `};
`

const Suffix = styled.span`
  margin-left: 2px;
`

const StyledArrowForwardIcon = styled(ArrowForwardIcon)<Pick<ValueChangeProps, 'view'>>`
  ${({ view }) => (view === 'column' ? 'transform: rotate(90deg);' : '')}
`

const StyledRow = styled(Row)`
  gap: 4px;
`

function getColor(newValue: CurrencyValue, oldValue: BigNumber, colors: CustomTheme['colors']) {
  return newValue.value.gt(oldValue) ? colors.Emerald : newValue.value.lt(oldValue) ? colors.PersianRed : colors.Black
}
