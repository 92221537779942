import { useCalls } from '@usedapp/core'
import { BlockchainCallResult } from './types'

export function toBlockchainCallResult<T>(
  skip: boolean | undefined,
  functionName: string,
  contractAddresses: (string | undefined)[],
  results: ReturnType<typeof useCalls>,
): BlockchainCallResult<T[]>[] {
  return results.map((result, index) => {
    if (skip || !contractAddresses[index]) {
      return getSkippedCallResponse<T[]>(functionName)
    }

    if (result === undefined) {
      return getLoadingCallResponse<T[]>()
    }

    if (result.error) {
      return getInvalidCallResponse<T[]>(result.error)
    }

    return getValidCallResponse<T[]>(result.value)
  })
}

function getSkippedCallResponse<T>(functionName: string): BlockchainCallResult<T> {
  return { data: undefined, error: new Error(`Contract method ${functionName} skipped`), loading: false }
}

function getLoadingCallResponse<T>(): BlockchainCallResult<T> {
  return { data: undefined, error: undefined, loading: true }
}

function getInvalidCallResponse<T>(error: Error): BlockchainCallResult<T> {
  return { data: undefined, error, loading: false }
}

function getValidCallResponse<T>(value: T): BlockchainCallResult<T> {
  return { data: value, error: undefined, loading: false }
}
