import { BigNumber } from 'ethers'
import styled, { useTheme } from 'styled-components'
import { Text } from 'components/atoms/Text'
import { CustomTheme } from 'styles'

export const PercentageValue = ({
  amount,
  totalAmount,
  asNewValue,
}: {
  amount: BigNumber
  totalAmount: BigNumber
  asNewValue?: boolean
}) => {
  const { colors } = useTheme()

  const percentageValue = !totalAmount.isZero() ? amount.mul(100).div(totalAmount) : undefined

  const color = getColor(amount, totalAmount, colors)

  return (
    <PercentageValueText variant="body2">
      (
      <Value asNewValue={asNewValue} valueColor={color}>
        {percentageValue?.toString() ?? '0'}
      </Value>
      %)
    </PercentageValueText>
  )
}

function getColor(newValue: BigNumber, oldValue: BigNumber, colors: CustomTheme['colors']) {
  return newValue.gt(oldValue) ? colors.Emerald : newValue.lt(oldValue) ? colors.PersianRed : colors.Black
}

const Value = styled.span<{ valueColor: string; asNewValue?: boolean }>`
  color: ${({ valueColor, asNewValue }) => (asNewValue ? valueColor : 'inherit')};
`

const PercentageValueText = styled(Text)`
  margin-left: 4px;
`
