import { BigNumber } from 'ethers'
import { PortfolioStatus } from './PortfolioStatus'
import { InvestorRestriction } from 'domain/investor/restrictions'
import { AssetOffering, CreditOffering, Offering, StructuredOffering } from 'types/Portfolio/StructuredOffering'
import { SerializableOffering, SerializablePotentialOffering } from 'types/Serializable'
import { CurrencyValue } from '@usedapp/core'
import { CapitalRaisingStrategy } from 'components/Portfolios/OfferingForm/types'
import { OfferingInitialDrawdown } from 'types/Portfolio/OfferingInititalDrawdown'

export interface PotentialOffering
  extends Omit<
    StructuredOffering,
    | 'address'
    | 'status'
    | 'targetApy'
    | 'manager'
    | 'tranches'
    | 'totalAccruedInterest'
    | 'totalInterestDisbursed'
    | 'maxSize'
    | 'latestCheckpoint'
    | 'isESignatureRequired'
    | 'apyCalculationMethod'
    | 'manualAPYOverride'
  > {
  _type: 'PotentialOffering'
  status: PortfolioStatus.Preview
  symbol: string
  minimumSize: BigNumber
  startDeadline: Date
  duration: number
  minimumInvestment: BigNumber
  investRequirements: InvestorRestriction
  targetFundraise: BigNumber
  noticePeriod: number
  expectedEquityRate: {
    from: number
    to: number
  }
  manager: {
    address: string | null
    name: string
    userId: string
    imageUrl: string | null
  }
  targetApy: string
  offeringType: AssetOffering['_type'] | CreditOffering['_type']
  fundraisingPeriod: number
  managerFee: number
  areLiveDepositsAllowed: boolean
  capitalRaisingStrategy: CapitalRaisingStrategy
  minimumInvestorCommitment: BigNumber
  initialDrawdown: OfferingInitialDrawdown | null
}

export function formatTargetAPY(expectedEquityRate: { from: number; to: number }) {
  return `${expectedEquityRate.from} - ${expectedEquityRate.to}`
}

export type OfferingIdentifier = Pick<Offering | PotentialOffering, '_type' | 'id'>

export function isPotentialOffering(
  offering: SerializableOffering | SerializablePotentialOffering,
): offering is SerializablePotentialOffering
export function isPotentialOffering(offering: Offering | PotentialOffering): offering is PotentialOffering
export function isPotentialOffering(
  offering: Offering | PotentialOffering | SerializableOffering | SerializablePotentialOffering,
): offering is PotentialOffering | SerializablePotentialOffering
export function isPotentialOffering(offering: OfferingIdentifier): offering is PotentialOffering
export function isPotentialOffering(
  offering: Offering | PotentialOffering | SerializableOffering | SerializablePotentialOffering | OfferingIdentifier,
): offering is PotentialOffering | SerializablePotentialOffering {
  return offering._type === 'PotentialOffering'
}

export interface PotentialOfferingWithInterestedIn extends PotentialOffering {
  interestedIn: {
    commitment: CurrencyValue
    numberOfInvestors: number
  }
}
