import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Overflows } from 'styles'
import { TextColor, TextInline, TextVariant } from 'components/atoms/Text'

export interface StatisticProps {
  label?: ReactNode
  text: ReactNode
  view?: 'row' | 'column'
  variant?: TextVariant
  statisticVariant?: TextVariant
  className?: string
  bold?: boolean
  extraBold?: boolean
  background?: boolean
  textColor?: TextColor
  wide?: boolean
  labelMedium?: boolean
}

export const Statistic = ({
  label,
  text,
  view = 'row',
  variant = 'body1',
  statisticVariant = 'body1',
  className,
  bold,
  extraBold,
  textColor = 'dark',
  wide,
  labelMedium,
}: StatisticProps) => {
  return (
    <StatisticElement view={view} wide={wide} className={className}>
      {label && (
        <StatisticLabel variant={statisticVariant} medium={labelMedium}>
          {label}
          {view === 'row' && ':'}
        </StatisticLabel>
      )}
      {text && (
        <StatisticText variant={variant} bold={bold} extraBold={extraBold} color={textColor}>
          {text}
        </StatisticText>
      )}
    </StatisticElement>
  )
}

export const StatisticElement = styled.div<Pick<StatisticProps, 'view' | 'wide'>>`
  display: flex;
  flex-direction: ${({ view }) => (view === 'column' ? 'column' : 'row')};
  justify-content: ${({ wide }) => (wide ? 'space-between' : 'flex-start')};
  gap: 4px;
`

const basicStatisticStyles = css`
  display: flex;
  align-items: center;
  ${Overflows.FullDots};
`

export const StatisticLabel = styled(TextInline)<{ medium?: boolean }>`
  ${basicStatisticStyles};
  font-weight: ${({ medium }) => {
    if (medium) return '500'
    return '300'
  }};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.Corduroy};
`

export const StatisticText = styled(TextInline)`
  ${basicStatisticStyles};
`
