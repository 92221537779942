import { CurrencyValue } from '@usedapp/core'

interface FormatOptions {
  fixedPrecisionDigits?: number
  shortenBigValues?: boolean
  maximumFractionDigits?: number
}

export function formatCurrencyValue(value: CurrencyValue, formatOptions?: FormatOptions) {
  if (value.isZero()) return '0'

  const formattedValue = value.format({
    fixedPrecisionDigits: formatOptions?.fixedPrecisionDigits ?? 2,
    useFixedPrecision: true,
    suffix: '',
  })

  if (formatOptions?.shortenBigValues) {
    const numberValue = parseFloat(formattedValue.replaceAll(',', ''))

    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 2,
      maximumFractionDigits: formatOptions.maximumFractionDigits ?? 4,
    }).format(numberValue)
  }

  return formattedValue
}
