import { ChangeEventHandler, FocusEventHandler, forwardRef, ReactNode } from 'react'
import styled from 'styled-components'
import { TextInline } from 'components/atoms/Text'
import { InputLabel } from './InputLabel'
import { commonInputBorderStyles, commonInputStyles, commonInputWrapperStyles } from 'components/atoms/common'

type InputAutocomplete = 'off' | 'on' | 'name' | 'email' | 'username' | 'organization'
type InputMode = 'text' | 'numeric' | 'email'

export interface InputProps {
  placeholder?: string
  label?: ReactNode | string
  disabled?: boolean
  value?: string
  className?: string
  error?: string
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  size?: 'normal' | 'flex'
  icon?: ReactNode
  onChange?: ChangeEventHandler<HTMLInputElement>
  id: string
  autoComplete?: InputAutocomplete
  inputMode?: InputMode
  maxWidth?: number
  isRequired?: boolean
  isOptional?: boolean
  innerSuffix?: string
  tooltip?: string | ReactNode
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    className,
    value,
    error,
    icon,
    disabled,
    size = 'normal',
    placeholder,
    label,
    onChange,
    id,
    autoComplete = 'off',
    inputMode = 'text',
    maxWidth,
    isRequired,
    isOptional,
    innerSuffix,
    tooltip,
    ...props
  },
  ref,
) {
  return (
    <StyledInputWrapper className={className} error={error} disabled={disabled} size={size} maxWidth={maxWidth}>
      {label && (
        <InputLabel
          htmlFor={id}
          error={error}
          disabled={disabled}
          isRequired={isRequired}
          isOptional={isOptional}
          tooltip={tooltip}
        >
          {label}
        </InputLabel>
      )}
      {icon}
      <StyledInput
        id={id}
        className={className}
        icon={icon}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete={autoComplete}
        value={value}
        ref={ref}
        disabled={disabled}
        inputMode={inputMode}
        {...props}
      />
      {innerSuffix && <InputInnerSuffix color="light">{innerSuffix}</InputInnerSuffix>}
    </StyledInputWrapper>
  )
})

export const StyledInputWrapper = styled.div<Pick<InputProps, 'error' | 'disabled' | 'size' | 'maxWidth'>>`
  display: flex;
  position: relative;
  align-items: center;
  column-gap: 4px;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'px' : '100%')};
  height: ${({ size }) => {
    switch (size) {
      case 'flex':
        return 'auto'
      case 'normal':
      default:
        return '48px'
    }
  }};
  padding: 8px 16px;

  ${commonInputBorderStyles}
  ${commonInputWrapperStyles}
  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.GrayNurse : theme.colors.White)};

  &.inner {
    height: 24px;
    padding: 0;
    border: none;
    border-right: 1px solid ${({ theme }) => theme.colors.Mouse};
    color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.Edward)};
  }
`

export const StyledInput = styled.input<InputProps>`
  width: 100%;
  height: 100%;
  padding: 4px 0 0;
  border: none;

  ${commonInputStyles}
  &,
  &:disabled {
    background-color: transparent;
  }
`

export const InputInnerSuffix = styled(TextInline)`
  width: fit-content;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.Iron};
  padding: 4px 0 0;
  margin-right: 10px;
`

export const BasicInputWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  max-width: 100%;
  padding-top: 10px;
  margin-top: -10px;
`
