import { PortfolioStatus, PotentialOffering, Tranche } from 'types/Portfolio/index'
import { CurrencyValue, Token } from '@usedapp/core'
import { PortfolioStatus as CarbonStructuredPortfolioStatus } from 'generated/graphql/structured-portfolios/types'

import { BigNumber } from 'ethers'
import {
  SerializableAssetOffering,
  SerializableCreditOffering,
  SerializableOffering,
  SerializablePotentialOffering,
} from 'types/Serializable'
import { DescriptionSection } from 'types/Manager'
import { CapitalRaisingStrategy } from 'components/Portfolios/OfferingForm/types'
import { OfferingInitialDrawdown } from './OfferingInititalDrawdown'
import { APYCalculationMethod, ManualAPYOverride } from 'types/Portfolio/APYCalculation'
import { SupportedChainId } from 'constants/chain'

export interface StructuredOfferingCheckpoint {
  totalAssets: BigNumber
  totalSupply: BigNumber
  createdAt: Date
}

export interface StructuredOffering {
  id: string
  address: string
  chainId: SupportedChainId
  name: string
  status: PortfolioStatus
  asset: Token
  tranches: Tranche[]
  startDate: Date
  endDate: Date
  creationDate: Date
  startDeadline: Date
  duration: number
  manager: {
    address: string
    name?: string
    userId?: string
    imageUrl?: string | null
  }
  descriptionSections: DescriptionSection[]
  dataRoomLink: string | null
  minimumSize: BigNumber
  targetApy: string | null
  latestCheckpoint: StructuredOfferingCheckpoint | null
  totalAccruedInterest: BigNumber
  totalInterestDisbursed: BigNumber
  capitalRaisingStrategy: CapitalRaisingStrategy
  minimumInvestorCommitment: BigNumber
  initialDrawdown: OfferingInitialDrawdown | null
  isESignatureRequired: boolean
  apyCalculationMethod: APYCalculationMethod
  manualAPYOverride: ManualAPYOverride | null
}

export interface CreditOffering extends StructuredOffering {
  _type: 'CreditOffering'
}

export interface AssetOffering extends StructuredOffering {
  _type: 'AssetOffering'
  currentApyInBPS: number
  outstandingAssets: CurrencyValue
  outstandingPrincipal: CurrencyValue
  outstandingInterest: CurrencyValue
  latestReportId: string
}

export type Offering = AssetOffering | CreditOffering

export const statusMap: Record<CarbonStructuredPortfolioStatus, PortfolioStatus> = {
  CapitalFormation: PortfolioStatus.Fundraising,
  Closed: PortfolioStatus.Closed,
  Live: PortfolioStatus.Open,
}

export function isCreditOffering(
  offering: SerializableOffering | SerializablePotentialOffering,
): offering is SerializableCreditOffering
export function isCreditOffering(offering: Offering | PotentialOffering): offering is CreditOffering
export function isCreditOffering(
  offering: Offering | PotentialOffering | SerializableOffering | SerializablePotentialOffering,
): offering is CreditOffering | SerializableCreditOffering {
  return offering._type === 'CreditOffering'
}

export function isAssetOffering(
  offering: SerializableOffering | SerializablePotentialOffering,
): offering is SerializableAssetOffering
export function isAssetOffering(offering: Offering | PotentialOffering): offering is AssetOffering
export function isAssetOffering(
  offering: Offering | PotentialOffering | SerializableOffering | SerializablePotentialOffering,
): offering is AssetOffering | SerializableAssetOffering {
  return offering._type === 'AssetOffering'
}
